import React from "react";
import { Box, Stack, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { StyledMainBox } from "../../../components/commonComponents/StyledComponents/styled";
import TextInputField from "../../../components/commonComponents/TextInputs/TextInputField";

import { Close, Save } from "@mui/icons-material";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";
import { useDispatch, useSelector } from "react-redux";
import { useCustomTranslation } from "../../../hooks/useCustomTranslation";
import { fetchWeekNumbers, validateEmpty, validateNumbers } from "../../../api_calls/utils";
import { toast } from "react-toastify";
import { saveMarketingActivities, updateMarketingActivities } from "../../../api_calls/marketingActivities";
import { deleteItems } from "../../../Store/actions";
import { useNavigate } from "react-router-dom";
import platformConfig from "../../../platformConfig";
import SingleSelect from "../../../components/commonComponents/Selectors/SingleSelect";
import MultiSelect from "../../../components/commonComponents/Selectors/MultiSelect";

const AddNewMarketingActivity = ({ open, onClose, size, usedFor, closeModal, campaignRecord }) => {
  const currentStateData = useSelector((state) => state.userSelections);
  const [fieldError, setFieldError] = React.useState({});
  const [activityData, setActivityData] = React.useState({});
  const [discountRange, setDiscountRange] = React.useState({ show: false });
  const [manualActivity, setManualActivity] = React.useState({ show: false });
  const dispatchAction = useDispatch();
  const navigate = useNavigate();
  const t = useCustomTranslation();

  // to calculate the discount range when discount price and original price are filled.
  const setDiscountedRange = () => {
    let discountPrice = document.getElementById("discountedPriceInput").value;
    let OriginalPrice = document.getElementById("originalPriceInput").value;

    if (OriginalPrice !== "" && discountPrice !== "") {
      // calculate the %age
      const percentageDiscount = (discountPrice / OriginalPrice) * 100;
      setDiscountRange({
        show: true,
        value: percentageDiscount.toFixed(2) + "%",
      });
    } else {
      setDiscountRange({
        show: false,
      });
    }
  };

  // to reset the pre-selected data or any new information added by user (when popup is closed)
  const reset_data = () => {
    setActivityData({});
    // reset in redux state also
    dispatchAction(
      deleteItems(["activity_ids_to_update", "activity_to_update", "activity_selections", "activity_ids_to_delete"])
    );
  };

  // validate when data is submitted
  const validateData = () => {
    let error = false;
    let errorMessages = {};
    const checkWeeks = typeof activityData.weeks !== "undefined" ? activityData.weeks : "";
    const checkStores = typeof activityData.stores !== "undefined" ? activityData.stores : "";
    const checkActivity = typeof activityData.activity_type !== "undefined" ? activityData.activity_type : "";
    const checkDiscount =
      typeof activityData.discounted_price !== "undefined" && activityData.discounted_price !== null
        ? parseFloat(activityData.discounted_price)
        : 0;
    const checkOriginalPrice =
      typeof activityData.original_price !== "undefined" && activityData.original_price !== null
        ? parseFloat(activityData.original_price)
        : 0;

    setFieldError({});

    //week number
    const validateWeek = validateEmpty(checkWeeks, true);
    if (validateWeek.error === true) {
      error = true;
      errorMessages = {
        ...errorMessages,
        weeks: "Please select weeks",
      };
    }
    // stores
    const validateStores = validateEmpty(checkStores, true);
    
    if (validateStores.error === true) {
      error = true;
      errorMessages = {
        ...errorMessages,
        stores: "Please select stores",
      };
    }
    // activity
    const validateActivity = validateEmpty(checkActivity, false);
    if (validateActivity.error === true) {
      error = true;
      errorMessages = {
        ...errorMessages,
        activity: "Please select activity",
      };
    }
    // discount price
    const validateDiscount = validateNumbers(checkDiscount, true);
    if (checkDiscount !== "" && checkDiscount !== null && validateDiscount.error === true) {
      error = true;
      errorMessages = {
        ...errorMessages,
        discount: "Please enter numbers only",
      };
    }
    // original price
    const validateOriginalPrice = validateNumbers(checkOriginalPrice, true);
    if (checkOriginalPrice !== "" && checkOriginalPrice !== null && validateOriginalPrice.error === true) {
      error = true;
      errorMessages = {
        ...errorMessages,
        original_price: "Please enter numbers only",
      };
    } else {
      if (checkOriginalPrice !== 0 && checkDiscount !== 0 && checkOriginalPrice <= checkDiscount) {
        error = true;
        errorMessages = {
          ...errorMessages,
          discount: "Discounted price must be less than the original price.",
        };
      }
    }

    if (error === true) {
      setFieldError({
        error: error,
        ...errorMessages,
      });
      toast("Please fill all the required details for marketing activity", { type: "error" });
    } else {
      // update or create the marketing activities.
      if (typeof currentStateData.activity_ids_to_update !== "undefined") {
        const dataToUpdate = {
          data_to_update: activityData,
          records_to_update: currentStateData.activity_ids_to_update,
        };

        updateMarketingActivities(dataToUpdate).then((info) => {
          if (info.success === true) {
            toast("Marketing activity updated successfully.", { type: "success" });
          } else {
            toast("Sorry, something went wrong.", "error");
          }
          reset_data();
          closeModal();
          // props.closePopup(false);
        });
      } else {
        saveMarketingActivities(activityData).then((info) => {
          if (info.success === true) {
            toast("Marketing activity added successfully.", { type: "success" });
          } else {
            toast("Sorry, something went wrong.", { type: "error" });
          }
          reset_data();
          closeModal();
          // props.closePopup(false);
        });
      }
    }
  };

  React.useEffect(() => {
    fetchWeekNumbers(campaignRecord.campaign_start_date, campaignRecord.campaign_end_date);
    // set data for the fields (if used for update)
    if (
      typeof currentStateData.activity_to_update !== "undefined" &&
      Object.keys(currentStateData.activity_to_update).length !== 0
    ) {
      setActivityData({
        ...currentStateData.activity_to_update,
      });
    }
    // save activity data to react state (required when updating an activity)
    if (typeof currentStateData.activity_selections !== "undefined") {
      setActivityData({
        ...currentStateData.activity_selections,
      });
    } else {
      setActivityData({
        ...activityData,
        campaign_id: campaignRecord.campaign_id,
        company_id: campaignRecord.retailer_company_id,
        year: new Date().getFullYear(),
      });
    }
  }, []);
  return (
    <Dialog
      open={open}
      onClose={(e) => {
        e.preventDefault();
      }}
      fullWidth={true}
      maxWidth={size}
    >
      <DialogTitle> Add new marketing activity</DialogTitle>
      <DialogContent>
        <StyledMainBox component="main">
          <Box>
            <Stack direction={"row"} spacing={4}>
              {/* ------- week numbers ------- */}
              <MultiSelect
                label="Calendar Weeks*"
                enableFullWidth={true}
                selectionCheck={(option, value) => option.value === value.value}
                options={typeof currentStateData.campaign_weeks !== "undefined" ? currentStateData.campaign_weeks : []}
                defaultVal={
                  typeof currentStateData.activity_to_update !== "undefined" &&
                  Object.keys(currentStateData.activity_to_update).length !== 0
                    ? currentStateData.activity_to_update.week_selection
                    : typeof activityData.weeks !== "undefined"
                    ? typeof currentStateData.campaign_weeks !== "undefined"
                      ? currentStateData.campaign_weeks.filter((weekInfo) => {
                          return activityData.weeks.includes(weekInfo.value);
                        })
                      : []
                    : []
                }
                onChangeCall={(selectedOption) => {
                  let userChoices = [];
                  selectedOption.forEach((chosen) => {
                    if (chosen.value !== "all") {
                      userChoices.push(chosen.value);
                    } else {
                      currentStateData.campaign_weeks.forEach((week) => {
                        if (week.value !== "all") {
                          userChoices.push(week.value);
                        }
                      });
                    }
                  });
                  setActivityData({
                    ...activityData,
                    weeks: userChoices,
                    week_selections: selectedOption,
                  });
                }}
                fieldError={
                  typeof fieldError.error !== "undefined" &&
                  fieldError.error === true &&
                  typeof fieldError.weeks !== "undefined"
                    ? fieldError.weeks
                    : ""
                }
              />
              {/* ---------- Gtin List and Gtin Description list --------- */}
              {typeof currentStateData.gtin_list !== "undefined" && currentStateData.gtin_list.length !== 0 && (
                <MultiSelect
                  label="GTIN"
                  enableFullWidth={true}
                  selectionCheck={(option, value) => option.value === value.value}
                  options={currentStateData.gtin_list.length !== 0 ? currentStateData.gtin_list : []}
                  defaultVal={
                    typeof currentStateData.activity_to_update !== "undefined" &&
                    Object.keys(currentStateData.activity_to_update).length !== 0
                      ? currentStateData.activity_to_update.gtinSelections
                      : typeof activityData.gtin_selection !== "undefined"
                      ? activityData.gtin_selection
                      : []
                  }
                  onChangeCall={(selectedOption) => {
                    let userChoices = [];
                    selectedOption.forEach((chosen) => {
                      if (chosen.value === "all") {
                        currentStateData.gtin_list.forEach((gtinNum) => {
                          if (gtinNum.value !== "all") {
                            userChoices.push({ gtin: gtinNum.value, description: gtinNum.label.split("-")[0] });
                          }
                        });
                      } else {
                        userChoices.push({ gtin: chosen.value, description: chosen.label.split("-")[0] });
                      }
                    });
                    setActivityData({
                      ...activityData,
                      gtin: userChoices,
                      gtin_selection: selectedOption,
                    });
                  }}
                />
              )}
            </Stack>
            <Box height={40} />
            <Stack direction={"row"} spacing={4}>
              {/* -------- Stores ------- */}
              {typeof currentStateData.filtered_stores !== "undefined" && currentStateData.filtered_stores.length !== 0 && (
                <MultiSelect
                  label="Stores*"
                  enableFullWidth={true}
                  selectionCheck={(option, value) => option.label === value.label}
                  options={currentStateData.filtered_stores.length !== 0 ? currentStateData.filtered_stores : []}
                  defaultVal={
                    typeof currentStateData.activity_to_update !== "undefined" &&
                    Object.keys(currentStateData.activity_to_update).length !== 0
                      ? currentStateData.activity_to_update.storeSelection
                      : typeof activityData.stores_selection !== "undefined"
                      ? activityData.stores_selection
                      : []
                  }
                  onChangeCall={(selectedOption) => {
                    let userChoices = [];
                    selectedOption.forEach((chosen) => {
                      if (chosen.value !== "all") {
                        userChoices.push({
                          store_id: chosen.id,
                          id_region: currentStateData.stores_detail[chosen.id].id_region,
                          market_type: currentStateData.stores_detail[chosen.id].market_type,
                        });
                      } else {
                        Object.keys(currentStateData.stores_detail).forEach((idStore) => {
                          userChoices.push({
                            store_id: idStore,
                            id_region: currentStateData.stores_detail[idStore].id_region,
                            market_type: currentStateData.stores_detail[idStore].market_type,
                          });
                        });
                      }
                    });
                    setActivityData({
                      ...activityData,
                      stores: userChoices,
                      stores_selection: selectedOption,
                    });
                  }}
                  fieldError={
                    typeof fieldError.error !== "undefined" &&
                    fieldError.error === true &&
                    typeof fieldError.stores !== "undefined"
                      ? fieldError.stores
                      : ""
                  }
                />
              )}

              {/* --------- Activity type -------- */}
              <SingleSelect
                enableFullWidth={true}
                label={t("activity_type_label") + "*"}
                selectionCheck={(option, value) => option.value === value.value}
                options={
                  typeof currentStateData.manual_activity_list !== "undefined"
                    ? [...currentStateData.manual_activity_list, ...platformConfig.activity_types]
                    : platformConfig.activity_types
                }
                defaultVal={
                  typeof currentStateData.activity_to_update !== "undefined" &&
                  Object.keys(currentStateData.activity_to_update).length !== 0
                    ? currentStateData.activity_to_update.activitySelection
                    : typeof activityData.activity_option_selected !== "undefined"
                    ? activityData.activity_option_selected
                    : ""
                }
                onChangeCall={(selectedOption) => {
                  if (selectedOption.value === "add_manually") {
                    setManualActivity({ show: true });
                    setActivityData({
                      ...activityData,
                      activity_name: "",
                      activity_type: currentStateData.next_available_activity,
                      activity_option_selected: selectedOption,
                    });
                  } else {
                    setManualActivity({ show: false });
                    setActivityData({
                      ...activityData,
                      activity_type: selectedOption.value,
                      activity_name: selectedOption.label,
                      activity_option_selected: selectedOption,
                    });
                  }
                }}
              />

              {/* --------- manual activity name --------- */}
              {manualActivity.show === true && (
                <TextInputField
                  fieldRequired={true}
                  elemId="activityNameInput"
                  label={t("activity_name_label")}
                  fieldName="activityNameInput"
                  showlabel={true}
                  defaultVal={typeof activityData.activity_name !== "undefined" ? activityData.activity_name : ""}
                  inputType={"text"}
                  onChangeCall={(e) => {
                    setActivityData({
                      ...activityData,
                      activity_type: currentStateData.next_available_activity,
                      activity_name: e.target.value,
                    });
                  }}
                />
              )}
            </Stack>

            {typeof activityData.activity_name !== "undefined" && activityData.activity_name === "Discount" && (
              <>
                <Box height={40} />
                <Stack direction={"row"} spacing={4}>
                  {/* ------- Discounted Price -------- */}
                  <TextInputField
                    fieldRequired={true}
                    elemId="discountedPriceInput"
                    label={t("discount_price_label")}
                    fieldName="discounted_price"
                    showlabel={true}
                    defaultVal={typeof activityData.discounted_price !== "undefined" ? activityData.discounted_price : ""}
                    inputType={"text"}
                    onChangeCall={(e) => {
                      setActivityData({
                        ...activityData,
                        discounted_price: e.target.value,
                      });
                      setDiscountedRange();
                    }}
                    fieldError={
                      typeof fieldError.error !== "undefined" &&
                      fieldError.error === true &&
                      typeof fieldError.discount !== "undefined"
                        ? fieldError.discount
                        : ""
                    }
                  />
                  {/* ------ original orice -------- */}
                  <TextInputField
                    fieldRequired={true}
                    elemId="originalPriceInput"
                    label={t("original_price_label")}
                    fieldName="original_price"
                    defaultVal={typeof activityData.original_price !== "undefined" ? activityData.original_price : ""}
                    showlabel={true}
                    inputType={"text"}
                    onChangeCall={(e) => {
                      setActivityData({
                        ...activityData,
                        original_price: e.target.value,
                      });
                      setDiscountedRange();
                    }}
                    fieldError={
                      typeof fieldError.error !== "undefined" &&
                      fieldError.error === true &&
                      typeof fieldError.original_price !== "undefined"
                        ? fieldError.original_price
                        : ""
                    }
                  />
                </Stack>
              </>
            )}

            <Box height={40} />
            {/* ----------- discount range ----------- */}
            {discountRange.show === true && (
              <Stack spacing={4} direction={"row"}>
                {/* --------- discounted range --------- */}
                <Typography variant="body2">{t("discount_range_text")}:</Typography>
                <Typography variant="body2">{discountRange.value}</Typography>
              </Stack>
            )}
          </Box>
        </StyledMainBox>
      </DialogContent>
      <Box height={20} />
      <DialogActions>
        <Stack direction={"row"} spacing={2}>
          <CloseButton
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
            label="Cancel"
            icon={<Close />}
          />
          <ActionButton
            onClick={(e) => {
              validateData();
            }}
            label={t("save_data_button_text")}
            icon={<Save />}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewMarketingActivity;
