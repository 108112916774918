import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  saveCampaignAdlevels,
  deleteSingleAdlevel,
  deleteMultipleAdlevels,
  updateCampaignAdlevels,
} from "../../../api_calls/campaignRequests";
import { deleteItems, updateStateData } from "../../../Store/actions";
import DownloadCSV from "../../../components/commonComponents/DownloadCsv/DownloadCSV";
import {
  checkUncheckAllCheckboxes,
  filterAdlevelResults,
  reArrangeAdlevelRedux,
  sortAdlevel,
} from "../../../api_calls/campaignSheet";
import { validateNumbers } from "../../../api_calls/utils";
import { toast } from "react-toastify";
import { validateAlphabets } from "../../../api_calls/utils";
import { useCustomTranslation } from "../../../hooks/useCustomTranslation";
import ConfirmationPopup from "../../../components/commonComponents/ConfirmationPopup";
import { Box, FormControlLabel, Paper, Stack, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import { ArrowDropDown, ArrowDropUp, Delete, Filter, FilterAlt, Save } from "@mui/icons-material";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import TextInputField from "../../../components/commonComponents/TextInputs/TextInputField";
import {
  HtmlTooltip,
  IconContainer,
  StyledWhiteCheckbox,
} from "../../../components/commonComponents/StyledComponents/styled";
import { StyledTableCell } from "../../../components/commonComponents/TabularLists/styles";
import { StyledCheckbox, StyledStack } from "../../../components/commonComponents/StyledComponents/styled";
import MultiSelect from "../../../components/commonComponents/Selectors/MultiSelect";

const ProductInformation = (props) => {
  const sheetDetails = useSelector((state) => state.userSelections);
  const dispatchAction = useDispatch();
  const [adlevelFilter, setAdlevelFilter] = React.useState([]);
  const [adlevelSelectedOption, setAdlevelSelectedOption] = React.useState([]);

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [deleteAdlevelId, setDeleteAdlevelId] = React.useState({});
  //use react state to manage product selections for creating adlevels.
  const [productSelection, setProductSelection] = React.useState([]);
  //to identify whether a single/multiple adlevel to be deleted on confirmation
  const [whichAdlevel, setWhichAdlevel] = React.useState("");
  //true = Ascending and false = descending
  const [sortMeasure, setSortMeasure] = React.useState(true);
  // to show error when there's any inaccuracy found in ad level names and numbers
  const [accuracyErrors, setAccuracyErrors] = React.useState({});
  //define csv headers and the corresponding key to look for in the data provided
  const csvHeaders = [
    { label: "Article Description", key: "description" },
    { label: "GTIN", key: "gtin" },
    { label: "Analysis Group Number", key: "numberAdLevel" },
    { label: "Analysis Group Name", key: "nameAdLevel" },
    // { label: "GTIN bundle", key: "gtinBundle" },
    { label: "Bundle size", key: "bundleSize" },
  ];

  const t = useCustomTranslation();

  let listItems =
    typeof sheetDetails.adlevel_filter_applied !== "undefined" && sheetDetails.adlevel_filter_applied === true
      ? sheetDetails.filtered_adlevels
      : typeof sheetDetails.created_adlevels !== "undefined"
      ? sheetDetails.created_adlevels
      : [];

  const filterAdlevels = (e) => {
    e.preventDefault();
    if (adlevelFilter.length !== 0) {
      filterAdlevelResults(adlevelFilter);
    } else {
      setAdlevelFilter([]);
      //update redux state
      dispatchAction(deleteItems(["adlevel_filter_applied", "filtered_adlevels"]));
    }
  };

  //update campaign sheet records and save to redux state
  const updateAdlevelRecords = (element, field_name, dataKey) => {
    const data = listItems;
    const fieldValue = element.target.value;
    let originalRecords = sheetDetails.created_adlevels;
    //update adlevel records
    let updatedAdlevelRecords = data.map((record, key) => {
      if (key === dataKey) {
        let updatedRecord = { ...record, [field_name]: fieldValue };
        //need to update the same record in original dataset (if filter is applied.)
        if (
          typeof sheetDetails.adlevel_filter_applied !== "undefined" &&
          sheetDetails.adlevel_filter_applied === true &&
          typeof record.actual_key !== "undefined"
        ) {
          originalRecords = originalRecords.map((orgRecord, orgKey) => {
            if (orgKey === record.actual_key) {
              return updatedRecord;
            }
            return orgRecord;
          });
        }
        //updated record for filtered data
        return updatedRecord;
      }
      return record;
    });

    //update redux state
    reArrangeAdlevelRedux(updatedAdlevelRecords, originalRecords);
  };

  //to show confirmation modal when delete feature is used
  const showDeleteConfirmationModal = (deleteAdlevelType) => {
    setWhichAdlevel(deleteAdlevelType);
    setShowDeleteModal(true);
  };

  //delete adlevel
  const deleteAdlevel = async () => {
    setShowDeleteModal(false);
    if (whichAdlevel === "Single") {
      const deleteAdlevelRecord = await deleteSingleAdlevel(deleteAdlevelId.adlevelid);
      if (deleteAdlevelRecord.success === true) {
        removeAdlevelRecord([deleteAdlevelId.adlevel_key]);
        //show success notification
        toast("Analytics product deleted successfully.", { type: "success" });
      } else {
        //show error notification
        toast("Sorry, something went wrong.", { type: "error" });
      }
    }

    if (whichAdlevel === "Multiple") {
      const deleteAdlevelRecords = await deleteMultipleAdlevels(productSelection);
      if (deleteAdlevelRecords.success === true) {
        removeAdlevelRecord(productSelection);
        setProductSelection([]);
        checkUncheckAllCheckboxes(false);
        //show success notification
        toast("Selected analytic product deleted successfully.", { type: "success" });
      } else {
        //show error notification
        toast("Sorry, something went wrong.", { type: "error" });
      }
    }
  };

  const removeAdlevelRecord = (toBeRemoved) => {
    let newArrWithDeletedAdlevel = [];
    const data = listItems;
    let originalRecords = sheetDetails.created_adlevels;
    let newArrWithDeletedOriginalAdlevel = [];
    let originalKeysToBeDeleted = [];

    data.forEach((record, key) => {
      if (!toBeRemoved.includes(key)) {
        newArrWithDeletedAdlevel.push(record);
      } else {
        if (typeof sheetDetails.adlevel_filter_applied !== "undefined" && sheetDetails.adlevel_filter_applied === true) {
          originalKeysToBeDeleted.push(record.actual_key);
        }
      }
    });

    if (originalKeysToBeDeleted.length !== 0) {
      originalRecords.forEach((record, key) => {
        if (!originalKeysToBeDeleted.includes(key)) {
          newArrWithDeletedOriginalAdlevel.push(record);
        }
      });
    }

    //save to redux
    reArrangeAdlevelRedux(newArrWithDeletedAdlevel, newArrWithDeletedOriginalAdlevel);
    //
    if (adlevelFilter.length !== 0) {
      filterAdlevelResults(adlevelFilter);
    }
    checkUncheckAllCheckboxes(false);
    //reset product selection
    setProductSelection([]);
  };

  const deleteSelectedAdlevels = async (e) => {
    e.preventDefault();
    if (productSelection.length !== 0) {
      showDeleteConfirmationModal("Multiple");
    } else {
      //show error notification
      toast("No products selected.", { type: "error" });
    }
  };

  // validate adlevel record for all the data format checks at once
  const validate_adlevel_for_all_checks = (adlevelItem) => {
    let error = false;
    let message = "";
    if (
      typeof adlevelItem.description === "undefined" ||
      (typeof adlevelItem.description !== "undefined" && adlevelItem.description === "")
    ) {
      error = true;
      message += message.length !== 0 ? ", " : "";
      message += "Article description must not be empty";
    }
    //validate gtin = it must be number
    const validateGtin = validateNumbers(typeof adlevelItem.gtin !== "undefined" ? adlevelItem.gtin : "");
    if (validateGtin.error === true) {
      error = true;
      message += message.length !== 0 ? ", " : "";
      message += "GTIN must not be empty and may not contain letters";
    }

    //validate adlevel number = must be number and not greater than 20
    const validateNumberAdlevel = validateNumbers(
      typeof adlevelItem.numberAdLevel !== "undefined" ? adlevelItem.numberAdLevel : ""
    );
    if (validateNumberAdlevel.error === true) {
      error = true;
      message += message.length !== 0 ? ", " : "";
      message += "Analysis group number must not be empty and may not contain letters";
    } else {
      if (adlevelItem.numberAdLevel > 20) {
        error = true;
        message += message.length !== 0 ? ", " : "";
        message += "Analysis group number can not be greater than 20";
      }
    }
    //validate adlevel name =  only alphabets
    const validateNameAdlevel = validateAlphabets(
      typeof adlevelItem.nameAdLevel !== "undefined" ? adlevelItem.nameAdLevel : ""
    );
    if (validateNameAdlevel.error === true) {
      error = true;
      message += message.length !== 0 ? ", " : "";
      message += "Analysis group name must not be empty and may not contain numbers";
    }

    // validate bundle size
    if (typeof adlevelItem.bundleSize !== "undefined") {
      const validateBundleSize = validateNumbers(adlevelItem.bundleSize);
      if (validateBundleSize.error === true) {
        error = true;
        message += message.length !== 0 ? ", " : "";
        message += "Bundle size cannot be empty and may not contain letters";
      } else {
        if (parseInt(adlevelItem.bundleSize) === 0) {
          error = true;
          message += message.length !== 0 ? ", " : "";
          message += "Bundle size cannot be 0";
        }
      }
    }

    if (error === true) {
      return {
        error: true,
        message: message,
      };
    } else {
      return {
        error: false,
        message: "validation passed",
      };
    }
  };

  // validate adlevel accuracy
  const validate_adlevel_accuracy = () => {
    let verifiedAdlevelNumber = {};
    let verifiedAdlevelName = {};
    let verifiedAdlevelGTINCombination = {};
    let productsObj = {};
    let gtinsObj = {};
    // const sortedData = sortAdlevel(true, listItems);
    let error = false;
    let errorMessages = {};
    let error_indexes = [];
    listItems.forEach((adlevelItem, index) => {
      // validate adlevel record before validating the accuracy of the adlevel
      const validateDataFormat = validate_adlevel_for_all_checks(adlevelItem);
      if (validateDataFormat.error === true) {
        error = true;
        errorMessages = {
          ...errorMessages,
          [index]: validateDataFormat.message,
        };
      } else {
        let adlevelNameKey = adlevelItem.nameAdLevel.replaceAll(" ", "_");
        let adlevelDesc = adlevelItem.description.replaceAll(" ", "_");
        let combinationKeyWithDesc =
          adlevelDesc + "-" + adlevelItem.gtin + "-" + adlevelItem.numberAdLevel + "-" + adlevelItem.nameAdLevel;
        let combinationKeyWithoutDesc = adlevelItem.gtin + "-" + adlevelItem.numberAdLevel + "-" + adlevelItem.nameAdLevel;
        //check if any adlevel is already verified or not.
        if (Object.keys(verifiedAdlevelNumber).length) {
          // Check if the combination of GTIN, numberAdLevel, and nameAdLevel already exists
          if (
            verifiedAdlevelGTINCombination[combinationKeyWithDesc] ||
            verifiedAdlevelGTINCombination[combinationKeyWithoutDesc]
          ) {
            error = true;
            errorMessages = {
              ...errorMessages,
              [index]:
                "Duplicate GTIN within analysis group " +
                adlevelItem.numberAdLevel +
                ". Only allowed once within same analysis group",
            };
            error_indexes.push(index);
          } else {
            // Add the combination to the verifiedAdlevelCombination object
            verifiedAdlevelGTINCombination[combinationKeyWithDesc] = true;
            verifiedAdlevelGTINCombination[combinationKeyWithoutDesc] = true;
          }

          // verify duplicate product name with same gtin
          if (Object.keys(productsObj).includes(adlevelDesc.toString())) {
            // if (productsObj[adlevelDesc] !== adlevelItem.gtin) {
            //   error = true;
            //   errorMessages = {
            //     ...errorMessages,
            //     [index]: "A specific Article Description must not be associated with multiple GTINs",
            //   };
            // }
          } else {
            // products unique array with gtin values
            // if (typeof productsObj[adlevelDesc] === "undefined") {
            //   productsObj = {
            //     ...productsObj,
            //     [adlevelDesc]: adlevelItem.gtin,
            //   };
            // }
          }
          // verify duplicate gtin name with same prod
          if (Object.keys(gtinsObj).includes(adlevelItem.gtin.toString())) {
            // if (gtinsObj[adlevelDesc] !== adlevelDesc) {
            //   error = true;
            //   errorMessages = {
            //     ...errorMessages,
            //     [index]: "A specific Article Description must not be associated with multiple GTINs",
            //   };
            // }
          } else {
            // if (typeof gtinsObj[adlevelItem.gtin] === "undefined") {
            //   gtinsObj = {
            //     ...gtinsObj,
            //     [adlevelItem.gtin]: adlevelDesc,
            //   };
            // }
          }

          // check if the adlevel number is already verified
          if (Object.keys(verifiedAdlevelNumber).includes(adlevelItem.numberAdLevel.toString())) {
            // check the accuracy of the adlevel number and name
            if (verifiedAdlevelNumber[adlevelItem.numberAdLevel] !== adlevelItem.nameAdLevel) {
              error = true;
              errorMessages = {
                ...errorMessages,
                [index]: "Analysis group name already exists for another analysis group. Choose another one",
              };
            }
          } else if (Object.keys(verifiedAdlevelName).includes(adlevelNameKey)) {
            // check the accuracy of the adlevel number and name
            if (verifiedAdlevelName[adlevelNameKey] !== adlevelItem.numberAdLevel) {
              error = true;
              errorMessages = {
                ...errorMessages,
                [index]: "Analysis group name already exists for another analysis group. Choose another one",
              };
            }
          } else {
            // adlevel item doesn't exist in the already verified items, so add a new record to verified variables
            verifiedAdlevelNumber = {
              ...verifiedAdlevelNumber,
              [adlevelItem.numberAdLevel]: adlevelItem.nameAdLevel,
            };
            verifiedAdlevelName = {
              ...verifiedAdlevelName,
              [adlevelNameKey]: adlevelItem.numberAdLevel,
            };
          }
        } else {
          // Add the combination to the verifiedAdlevelCombination object
          verifiedAdlevelGTINCombination[combinationKeyWithoutDesc] = true;
          verifiedAdlevelGTINCombination[combinationKeyWithDesc] = true;

          //verified adlevel is empty, add first record
          verifiedAdlevelNumber = {
            ...verifiedAdlevelNumber,
            [adlevelItem.numberAdLevel]: adlevelItem.nameAdLevel,
          };
          verifiedAdlevelName = {
            ...verifiedAdlevelName,
            [adlevelNameKey]: adlevelItem.numberAdLevel,
          };
          // products unique array with gtin values
          if (typeof productsObj[adlevelDesc] === "undefined") {
            productsObj = {
              ...productsObj,
              [adlevelDesc]: adlevelItem.gtin,
            };
          }
          if (typeof gtinsObj[adlevelItem.gtin] === "undefined") {
            gtinsObj = {
              ...gtinsObj,
              [adlevelItem.gtin]: adlevelDesc,
            };
          }
        }
      }
    });

    return { error, errorMessages, verifiedAdlevelNumber, verifiedAdlevelName, error_indexes };
  };

  //to send an api request to save campaign sheet data to the database
  const saveAdlevelRecords = async (e) => {
    e.preventDefault();
    setAccuracyErrors({});
    const validateData = validate_adlevel_accuracy();
    if (validateData.error === false) {
      const updateRecords =
        props.usedFor === "Upload" ? await saveCampaignAdlevels(props.campaignId) : await updateCampaignAdlevels(listItems);
      if (updateRecords.success === true) {
        // check if any error returned from the api
        if (
          typeof updateRecords.api_response !== "undefined" &&
          typeof updateRecords.api_response.response.success !== "undefined" &&
          updateRecords.api_response.response.success === false
        ) {
          toast(updateRecords.api_response.response.error_detail, { type: "error" });
        } else {
          //show success notification
          const message =
            props.usedFor === "Upload" ? "Analysis groups added successfully." : "Analysis groups updated successfully.";
          toast(message, { type: "success" });
          //reset filter and reset all selected checkboxes
          checkUncheckAllCheckboxes(false);
          dispatchAction(
            updateStateData({
              filter_applied: false,
            })
          );
          if (props.usedFor === "Upload") {
            props.closeModal();
          }
        }
      } else {
        //show error notification
        toast("Sorry, something went wrong.", { type: "error" });
      }
    } else {
      setAccuracyErrors(validateData.errorMessages);
      toast("Please fill in valid information", { type: "error" });
    }
  };

  const deleteAdlevelAfterConfirmation = () => {
    if (props.usedFor === "Upload") {
      setAccuracyErrors({});
      removeAdlevelRecord(productSelection);
    } else {
      deleteAdlevel();
    }
  };

  return (
    <>
      {/* --------- show campaign sheet content along with checkboxes --------- */}
      {typeof sheetDetails.created_adlevels !== "undefined" && sheetDetails.created_adlevels.length !== 0 ? (
        <Box>
          <Stack direction={"row"} spacing={4}>
            {props.usedFor === "Upload" && <h3 className="cyreen_heading">{t("product_information_heading_text")} </h3>}

            {/* : (
              <h3 className="cyreen_heading">{t("create_your_adlevels_text")}</h3>
            )} */}
          </Stack>
          <Box height={40} />
          {/* {props.usedFor !== "Upload" && <AddManualProduct />} */}

          {/* ------------ filters ----------- */}
          <Stack direction={"row"} spacing={4}>
            {/* --------- adlevel name --------- */}
            <MultiSelect
              label="Analysis Group Name"
              enableFullWidth={false}
              options={sheetDetails.adlevel_names.length !== 0 ? sheetDetails.adlevel_names : []}
              selectionCheck={(option, value) => option.value === value.value}
              defaultVal={adlevelSelectedOption}
              usedFor="filter"
              onChangeCall={(selectedOption) => {
                let userChoices = [];
                selectedOption.forEach((chosen) => {
                  userChoices.push(chosen.value);
                });

                setAdlevelFilter(userChoices);
                setAdlevelSelectedOption(selectedOption);
              }}
            />

            {/* ------------ filter button ----------- */}
            <ActionButton onClick={filterAdlevels} label={"Apply Filter"} icon={<FilterAlt />} />
          </Stack>
          <Box height={40} />
          {/* --------delete multiple adlevel button --------- */}
          <Stack direction={"row"} spacing={4}>
            {typeof sheetDetails.campaign_detail !== "undefined" &&
              ![2, 4].includes(sheetDetails.campaign_detail.campaign_status_code) && (
                <ActionButton onClick={deleteSelectedAdlevels} label={t("delete_selected_button_text")} icon={<Delete />} />
              )}
          </Stack>
          <Box height={40} />
          {/* ----------- products table ---------- */}
          <Stack direction={"row"} spacing={4}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 400 }} aria-label="customized table">
                {/* ------ header row ------ */}
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left" className="text-wrapper">
                      <FormControlLabel
                        control={
                          <StyledWhiteCheckbox
                            onChange={(e) => {
                              checkUncheckAllCheckboxes(e.target.checked);
                              if (e.target.checked === true) {
                                let selectedItems = [];
                                listItems.map((record, key) => {
                                  selectedItems.push(key);
                                });
                                setProductSelection(selectedItems);
                              } else {
                                //reset the selection
                                setProductSelection([]);
                              }
                            }}
                          />
                        }
                        label="Select All"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left" className="text-wrapper">
                      Article Description
                    </StyledTableCell>
                    <StyledTableCell align="left" className="text-wrapper">
                      GTIN
                    </StyledTableCell>
                    {/* --------- adlevel number ------- */}
                    <StyledTableCell align="left" className="text-wrapper">
                      {t("adlevel_number_text")}{" "}
                      {sortMeasure === true ? (
                        <ArrowDropDown
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setSortMeasure(!sortMeasure);
                            sortAdlevel(!sortMeasure, listItems);
                          }}
                        />
                      ) : (
                        <ArrowDropUp
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setSortMeasure(!sortMeasure);
                            sortAdlevel(!sortMeasure, listItems);
                          }}
                        />
                      )}
                    </StyledTableCell>
                    {/* ------- adlevel name -------- */}
                    <StyledTableCell align="left" className="text-wrapper">
                      {t("adlevel_name_text")}
                    </StyledTableCell>
                    {/* -------- bundle size --------- */}
                    <StyledTableCell align="left" className="text-wrapper">
                      {t("bundle_size_text")}
                    </StyledTableCell>
                    {/* ------- action column --------- */}
                    <StyledTableCell align="left" className="text-wrapper"></StyledTableCell>
                  </TableRow>
                </TableHead>
                {/* -------- table body ------- */}
                <TableBody>
                  {/* ----- adlevel list items will be sorted by adlevel number by default ------ */}
                  {listItems.length !== 0 &&
                    listItems.map((adlevel, key) => (
                      <TableRow
                        key={"campaign_id_" + key}
                        className={
                          Object.keys(accuracyErrors).length !== 0 && Object.keys(accuracyErrors).includes(key.toString())
                            ? "cyreen_adlevel_error"
                            : "cyreen_no_error"
                        }
                      >
                        {/* ------ select checkbox ----- */}
                        <StyledTableCell component="td" scope="row" className="text-wrapper">
                          <StyledStack direction={"row"}>
                            <FormControlLabel
                              control={
                                <StyledCheckbox
                                  id={"ID_campaign_id_" + key}
                                  checked={productSelection.includes(key)}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setProductSelection((prevSelection) => [...prevSelection, key]);
                                    } else {
                                      setProductSelection((prevSelection) => prevSelection.filter((item) => item !== key));
                                    }
                                  }}
                                  // onChange={(e) => {
                                  //   if (e.target.checked) {
                                  //     setProductSelection([...productSelection, key]);
                                  //   } else {
                                  //     const indexOfKey = productSelection.indexOf(key);
                                  //     if (indexOfKey > -1) {
                                  //       // only splice array when item is found
                                  //       productSelection.splice(indexOfKey, 1); // 2nd parameter means remove one item only
                                  //     }
                                  //     setProductSelection(productSelection.length === 0 ? [] : productSelection);
                                  //   }
                                  // }}
                                />
                              }
                              label=""
                            />
                          </StyledStack>
                        </StyledTableCell>
                        {/* -------- Product description ------- */}
                        <StyledTableCell component="td" scope="row" className="text-wrapper">
                          <StyledStack direction={"row"}>
                            <TextInputField
                              fieldRequired={true}
                              elemId="artikel_desc"
                              label=""
                              fieldName="artikel_desc"
                              showlabel={true}
                              defaultVal={adlevel.description}
                              inputType={"text"}
                              onChangeCall={(e) => {
                                updateAdlevelRecords(e, "description", key);
                              }}
                            />
                          </StyledStack>
                        </StyledTableCell>
                        {/* --------- GTIN ------- */}
                        <StyledTableCell component="td" scope="row" className="text-wrapper">
                          <StyledStack direction={"row"}>
                            <TextInputField
                              fieldRequired={true}
                              elemId="gtin"
                              label=""
                              fieldName="gtin"
                              showlabel={true}
                              defaultVal={adlevel.gtin}
                              inputType={"text"}
                              onChangeCall={(e) => {
                                updateAdlevelRecords(e, "gtin", key);
                              }}
                            />
                          </StyledStack>
                        </StyledTableCell>
                        {/* ------ Adlevel number ------- */}
                        <StyledTableCell component="td" scope="row" className="text-wrapper">
                          <StyledStack direction={"row"}>
                            <TextInputField
                              fieldRequired={true}
                              classDiv={"form-control adlevel_num_" + adlevel.numberAdLevel}
                              elemId="gtin"
                              label={""} //t("adlevel_number_text")
                              fieldName="adlevel_number_text"
                              showlabel={true}
                              defaultVal={adlevel.numberAdLevel}
                              inputType={"text"}
                              onChangeCall={(e) => {
                                updateAdlevelRecords(e, "numberAdLevel", key);
                              }}
                            />
                          </StyledStack>
                        </StyledTableCell>
                        {/* ------ Adlevel name ------- */}
                        <StyledTableCell component="td" scope="row" className="text-wrapper">
                          <StyledStack direction={"row"}>
                            <TextInputField
                              fieldRequired={true}
                              elemId="gtin"
                              label={""} //t("adlevel_name_text")
                              fieldName="adlevel_name_text"
                              showlabel={true}
                              defaultVal={adlevel.nameAdLevel}
                              inputType={"text"}
                              onChangeCall={(e) => {
                                updateAdlevelRecords(e, "nameAdLevel", key);
                              }}
                            />
                          </StyledStack>
                        </StyledTableCell>
                        {/* ---------- bundle size ----------- */}
                        <StyledTableCell component="td" scope="row" className="text-wrapper">
                          <StyledStack direction={"row"}>
                            <TextInputField
                              fieldRequired={true}
                              elemId="gtin"
                              label={""} //t("bundle_size_text")
                              fieldName="bundle_size_text"
                              showlabel={true}
                              defaultVal={adlevel.bundleSize}
                              inputType={"text"}
                              onChangeCall={(e) => {
                                updateAdlevelRecords(e, "bundleSize", key);
                              }}
                            />
                          </StyledStack>
                        </StyledTableCell>
                        {/* -------- delete button for adlevels -------- */}
                        <StyledTableCell component="td" scope="row" className="text-wrapper">
                          {typeof sheetDetails.campaign_detail !== "undefined" &&
                            ![2, 4].includes(sheetDetails.campaign_detail.campaign_status_code) && (
                              <StyledStack direction={"row"}>
                                <HtmlTooltip title={"Delete"}>
                                  <IconContainer>
                                    <a
                                      href="#campaignSheetUpload"
                                      className="cyreen_icons"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (props.usedFor === "Upload") {
                                          setProductSelection([key]);
                                        } else {
                                          setDeleteAdlevelId({
                                            adlevel_key: key,
                                            adlevelid: adlevel.id,
                                          });
                                        }
                                        showDeleteConfirmationModal("Single");
                                      }}
                                    >
                                      <Delete sx={{ color: "#AF3241" }} />
                                    </a>
                                  </IconContainer>
                                </HtmlTooltip>
                              </StyledStack>
                            )}
                        </StyledTableCell>

                        {/* ----------- adlevel accuracy error message ---------- */}
                        {Object.keys(accuracyErrors).length !== 0 &&
                          Object.keys(accuracyErrors).includes(key.toString()) && (
                            <StyledTableCell component="td" scope="row" className="text-wrapper">
                              {/* className="cyreen_error_text" */}
                              <StyledStack direction={"row"}>
                                <span className="cyreen_input_errors">{accuracyErrors[key]}</span>
                              </StyledStack>
                            </StyledTableCell>
                          )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
          <Box height={40} />
          {/* --- Save button >> data will be sent to backend on click of this button --- */}
          <StyledStack direction={"row"} spacing={4}>
            {typeof sheetDetails.campaign_detail !== "undefined" &&
              ![2, 4].includes(sheetDetails.campaign_detail.campaign_status_code) && (
                <ActionButton
                  onClick={saveAdlevelRecords}
                  label={props.usedFor === "Upload" ? t("save_adlevel_button_text") : t("update_adlevel_button_text")}
                  icon={<Save />}
                />
              )}

            {/* ---------------- download csv button ----------- */}
            <DownloadCSV
              csvData={listItems}
              csvHeaders={csvHeaders}
              filename={
                typeof sheetDetails.campaign_detail !== "undefined"
                  ? "export_" + sheetDetails.campaign_detail.campaign_name + "_products.csv"
                  : "products.csv"
              }
            />
          </StyledStack>

          {/* ----------- delete adlevel confirmation modal -------- */}
          {showDeleteModal === true && (
            <ConfirmationPopup
              showConfirmationPopup={showDeleteModal}
              confirmationText={t("delete_adlevel_confirmation_text")}
              closePopup={setShowDeleteModal}
              confirmationActionCall={deleteAdlevelAfterConfirmation}
            />
          )}
        </Box>
      ) : (
        <>
          {typeof sheetDetails.loading !== "undefined" && sheetDetails.loading === true ? (
            <p>{t("data_loading_wait_time_text")}</p>
          ) : (
            <p>{t("no_adlevel_added_text")}</p>
          )}
        </>
      )}
    </>
  );
};

export default ProductInformation;
