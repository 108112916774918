import React from "react";
import { Box, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
import {
  IconContainer,
  StyledRightAlignedStack,
  StyledTabs,
} from "../../../components/commonComponents/StyledComponents/styled";

import { useNavigate, useParams } from "react-router-dom";
import { campaign_analytics_tabs_config } from "../dashboardsConfig";

import { useDispatch, useSelector } from "react-redux";
import { pageAccessAllowed } from "../../../api_calls/userManagement";
import ActionButton, { LightGreyButton } from "../../../components/commonComponents/Buttons/ActionButton";
import { updateStateData } from "../../../Store/actions";
import BrandReportFilter from "./BrandReportFilter";
import { Menu, TableRows } from "@mui/icons-material";

// to load tab content
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AnalyticsView = ({ viewMode }) => {
  const [value, setValue] = React.useState(0);
  const [reportTabs, setReportTabs] = React.useState([]);

  const { id } = useParams();
  const navigate = useNavigate();
  const campaignDetails = useSelector((state) => state.userSelections);
  const checkEditAccess = pageAccessAllowed("campaigns", "can_edit", []);
  const dispatchAction = useDispatch();
  const [showBrandFilter, setBrandFilterPopup] = React.useState(false);
  const [isTabsVisible, setIsTabsVisible] = React.useState(true); // State to manage tabs visibility
  const [scrollPos, setScrollPos] = React.useState(0);
  const [isToggleIconVisible, setIsToggleIconVisible] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleTabsVisibility = () => {
    setIsTabsVisible(!isTabsVisible);
  };

  React.useEffect(() => {
    const reportTabsConfig = campaign_analytics_tabs_config(viewMode);
    setReportTabs(reportTabsConfig);
  }, []);

  React.useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      // Show tabs when at the top of the page
      if (currentScrollPos === 0) {
        setIsTabsVisible(true);
        setIsToggleIconVisible(false);
      }
      // Hide tabs on scroll down
      if (currentScrollPos > scrollPos) {
        setIsToggleIconVisible(true);
        setIsTabsVisible(false);
      }
      setScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPos]);

  return (
    <>
      {reportTabs.length !== 0 && (
        <>
          {isTabsVisible && (
            <Box
              sx={{
                display: "flex", // Make the Box a flex container
                borderBottom: 1,
                borderColor: "divider",
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: "#fff",
              }}
            >
              <Box component={"div"} sx={{ marginTop: "5px" }}>
                <StyledTabs value={value} onChange={handleChange} aria-label="brand analytics" textColor={"#F04B55"}>
                  {reportTabs.map((tabDetail) => {
                    // icon={tabDetail.icon}
                    return <Tab key={tabDetail.unique_key} label={tabDetail.label} {...a11yProps(0)} />;
                  })}
                </StyledTabs>
              </Box>

              {/* ----------- brand report buttons ----------- */}
              <Box
                component={"div"}
                sx={{
                  flex: 1,
                  marginTop: "5px",
                }}
              >
                {checkEditAccess.success === true && campaignDetails.campaign_detail.campaign_status_code === 4 && (
                  <StyledRightAlignedStack direction={"row"} spacing={2}>
                    <LightGreyButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatchAction(
                          updateStateData({
                            brand_report_view: viewMode === "Brand" ? false : true,
                          })
                        );

                        if (viewMode === "Brand") {
                          navigate("/check-campaigns/campaign-analytics/" + id);
                        } else {
                          navigate("/check-campaigns/brand-report-view/" + id);
                        }
                      }}
                      label={viewMode === "Brand" ? "Retailer View" : "Brand View"}
                      icon={<></>}
                      selected={false}
                    />
                    <ActionButton
                      onClick={(e) => {
                        e.preventDefault();
                        setBrandFilterPopup(!showBrandFilter);
                      }}
                      label={"Brand Report"}
                      icon={<></>}
                    />
                  </StyledRightAlignedStack>
                )}
              </Box>
            </Box>
          )}

          {/* ----------- filters section ------------ */}
          <Box
            sx={{
              display: "flex", // Make the Box a flex container
              // borderBottom: 1,
              // borderColor: "divider",
              position: "sticky",
              flexWrap: "wrap",
              top: isTabsVisible ? 45 : 0,
              zIndex: 1,
              backgroundColor: "#fff",
            }}
          >
            <Box width={500} component={"div"}>
              {/* <StyledBlackInformationTypography variant="h5" sx={{ marginTop: "30px" }}>
                Performance - {campaignDetails.campaign_detail.campaign_name}
              </StyledBlackInformationTypography> */}
              {/* Toggle Button */}
              {isToggleIconVisible && campaignDetails.campaign_detail.campaign_status_code === 4 && (
                <IconButton
                  sx={{
                    top: "25px",
                    left: "25px",
                  }}
                >
                  <TableRows onClick={toggleTabsVisibility} />
                </IconButton>
              )}
            </Box>
            <Box
              component={"div"}
              width={600}
              sx={{
                flex: 1,
              }}
            >
              {reportTabs[value].filter_component}
            </Box>
          </Box>

          {/* ============== tab contents =========== */}
          {reportTabs.length !== 0 &&
            reportTabs.map((tabDetail, index) => {
              return (
                <CustomTabPanel value={value} index={index}>
                  {tabDetail.dashboard}
                </CustomTabPanel>
              );
            })}

          {showBrandFilter && (
            <BrandReportFilter
              open={showBrandFilter}
              onClose={() => {
                setBrandFilterPopup(!showBrandFilter);
              }}
              size={"md"}
              closeModal={() => {
                setBrandFilterPopup(!showBrandFilter);
              }}
              usedFor={"update"}
              campaignRecord={typeof campaignDetails.campaign_detail !== "undefined" ? campaignDetails.campaign_detail : {}}
            />
          )}
        </>
      )}
    </>
  );
};

export default AnalyticsView;
